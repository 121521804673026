import { Button, Checkbox, Input, Modal } from "antd";
import { FaArrowRight } from "react-icons/fa6";
import restaurant from "../assests/restaurant.png";

import React, { useState } from "react";
import axios from "axios";

export default function Calltoaction() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState("");
  const [businessname, setBusinessName] = useState("");
  const [notifications, setNotifications] = useState(false);
  const [businessemail, setBusinessEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const endPoint = "https://pairable-app.azurewebsites.net/api/waiting";

      const payload = {
        email: email,
        early_access: true,
        notifications: notifications,
        subscribed: false,
        business_name: businessname,
        business_email: businessemail,
        contact_full_name: fullName,
        phone: contactNumber,
        mode: "business",
      };

      const res = await axios.post(endPoint, payload);
      console.log("res", res);
      setFullName("");
      setBusinessName("");
      setBusinessEmail("");
      setContactNumber("");
      setEmail("");
      setNotifications(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error during signup:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="call-to-action-container">
        <div className="call-to-action-content">
          <p className="call-to-action-title">
            Become an Interested Restaurant Partner When we go Live!
          </p>
          <p className="call-to-action-subtitle">
            Sign up for early access to Pairable Web or receive notifications
            for our official launch launch.
          </p>
          <p className="call-to-action-privacy">
            We respect your privacy. No spam, just updates!
          </p>
          <div className="call-to-action-button">
            <Button
              onClick={showModal}
              style={{
                fontSize: 16,
                fontWeight: 600,
                padding: 20,
                borderRadius: 30,
                backgroundColor: "#FFFFFF",
                color: "#C00202",
              }}
            >
              Signup for Early access <FaArrowRight />
            </Button>

            {/* <Button
            style={{
              fontSize: 16,
              fontWeight: 600,
              padding: 20,
              borderRadius: 30,
              background: "none",
              color: "#ffffff",
              border: "none",
            }}
          >
            Get Notified When We Launch
          </Button> */}
          </div>
        </div>

        <div>
          <img src={restaurant} alt="" className="call-to-action-image" />
        </div>
      </div>
      <Modal
        open={isModalVisible}
        onCancel={handleCancel}
        width={450}
        maskClosable={false}
        centered
        footer={null}
      >
        <p className="modal-title">Enter Your Details</p>
        <div className="modal-content">
          <Input
            placeholder="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            className="modal-input"
          />
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="modal-input"
          />
          <Input
            placeholder="Business Name"
            value={businessname}
            onChange={(e) => setBusinessName(e.target.value)}
            className="modal-input"
          />
          <Input
            placeholder="Business Email"
            value={businessemail}
            onChange={(e) => setBusinessEmail(e.target.value)}
            className="modal-input"
          />
          <Input
            placeholder="Contact Phone Number"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            className="modal-input"
            type="tel"
          />
          <Checkbox
            checked={notifications}
            onChange={(e) => setNotifications(e.target.checked)}
          >
            Enable Notification
          </Checkbox>
          <Button
            onClick={handleSubmit}
            loading={loading}
            style={{
              fontSize: "16px",
              fontWeight: 600,
              color: "#FFFFFF",
              width: "100%",
              height: "auto",
              background: `linear-gradient(90deg, #770f27 -25.71%, #c00202 50.49%, #770f27 115.17%)`,
              borderRadius: "200px",
              textTransform: "uppercase",
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}
