import React from "react";
import logo from "../assests/logo 1.png";
import "../styles/Home.css";

export default function Header() {
  return (
    <div className="header-container">
      <img src={logo} alt="pairable" className="header-logo" />
      <p className="header-text">
        Elevate Your Dining Experience with AI-Powered Wine Pairing...
      </p>
    </div>
  );
}
